
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    img: {
      type: String,
    },
    icon: {
      type: String,
    },
    heading: {
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
    secondary: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  setup(props) {
    const imgUrl = `${window.location.origin}/assets/images/${props.img}`;
    return { imgUrl };
  },
});
