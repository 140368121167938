
import { Objectives, SurveyCgmInitial, SurveyCgmInitialFormDto, SurveyType, WeightQuantity } from '@/models/Survey';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import CardComponent from '../CardComponent.vue';
import HealzInput from '@/components/healz/ui/HealzInput.vue';
import MultiSelectBox from '../common/MultiSelectBox.vue';
import EmptyState from '../EmptyState.vue';
import SurveyHelper from '@/helpers/Surveys.helper';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
  components: { EmptyState, CardComponent, MultiSelectBox, HealzInput },
  setup() {
    const toast = useToast();
    const { t } = useI18n();
    const route = useRoute();
    const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;
    const isAnthropometricDataNotReady = ref(true);
    const editMode = ref(false);
    const waitSubmit = ref(false);

    const refObjectiveDescription = ref();
    const refWeightLoseQuantity = ref();
    const refWeightLoseDietTested = ref();
    const refWeightGainQuantity = ref();
    const refWeightGainDietTested = ref();

    const surveyCgmInitialDefault = ref<SurveyCgmInitial>();
    const surveyCgmInitialFormDto = ref<SurveyCgmInitialFormDto>();

    const fetchCgmInitialSurvey = async () => {
      const result = await patientService.findSurveyCgmInitial(patientId);
      if (!(result instanceof ServiceError)) {
        surveyCgmInitialDefault.value = { ...result };
        surveyCgmInitialFormDto.value = new SurveyCgmInitialFormDto(surveyCgmInitialDefault.value);
        if (!surveyCgmInitialFormDto.value.id) {
          surveyCgmInitialFormDto.value.id = SurveyType.CGM_INITIAL;
        }
        isAnthropometricDataNotReady.value = false;
      }
    };

    onMounted(async () => {
      await fetchCgmInitialSurvey();
    });

    const onSubmit = async () => {
      if (surveyCgmInitialFormDto.value) {
        try {
          waitSubmit.value = true;
          await patientService.modifySurveyCgmInitial(patientId, surveyCgmInitialFormDto.value.toSurveyCgmInitial());
          toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditPatient')}`, life: 3000 });
        } catch (error) {
          toast.add({
            severity: 'error',
            summary: `${t('messages.notifications.errorEditPatient')} ${t('messages.notifications.tryLater')}`,
            life: 3000,
          });
        } finally {
          waitSubmit.value = false;
          editMode.value = false;
        }
      }
    };

    const onCancelEdition = async () => {
      editMode.value = false;
      setInitialVaules();
    };

    const setInitialVaules = () => {
      if (surveyCgmInitialDefault.value) {
        surveyCgmInitialFormDto.value = new SurveyCgmInitialFormDto(surveyCgmInitialDefault.value);
        resetAllMultiSelect();
      }
    };

    const resetAllMultiSelect = () => {
      refObjectiveDescription.value?.setInitialValues();
      refWeightLoseQuantity.value?.setInitialValues();
      refWeightLoseDietTested.value?.setInitialValues();
      refWeightGainQuantity.value?.setInitialValues();
      refWeightGainDietTested.value?.setInitialValues();
    };

    const createComputedOptions = (key: string, options: any, answer: any) => {
      if (surveyCgmInitialDefault.value && surveyCgmInitialFormDto.value) {
        return SurveyHelper.createOptionsObject(key, options, answer);
      }
      return [];
    };

    const objectiveOptions = computed(() =>
      createComputedOptions(
        'personData.objectives',
        Objectives,
        surveyCgmInitialDefault.value?.answers?.HEALZ_OBJECTIVE_DESCRIPTION,
      ),
    );

    const weightLoseQuantityOptions = computed(() =>
      createComputedOptions(
        'personData.weightLoseQuantity',
        WeightQuantity,
        surveyCgmInitialDefault.value?.answers?.WEIGHT_LOSE_QUANTITY,
      ),
    );

    const weightGainQuantityOptions = computed(() =>
      createComputedOptions(
        'personData.weightGainQuantity',
        WeightQuantity,
        surveyCgmInitialDefault.value?.answers?.WEIGHT_GAIN_QUANTITY,
      ),
    );

    const weightLoseDietTestedQuantityOptions = computed(() =>
      SurveyHelper.generateYesNoOptions('WEIGHT_LOSE_DIET_TESTED', surveyCgmInitialDefault.value?.answers, t),
    );

    const weightGainDietTestedQuantityOptions = computed(() =>
      SurveyHelper.generateYesNoOptions('WEIGHT_GAIN_DIET_TESTED', surveyCgmInitialDefault.value?.answers, t),
    );

    return {
      isAnthropometricDataNotReady,
      surveyCgmInitialFormDto,
      editMode,
      waitSubmit,
      Objectives,
      objectiveOptions,
      weightLoseQuantityOptions,
      weightLoseDietTestedQuantityOptions,
      weightGainQuantityOptions,
      weightGainDietTestedQuantityOptions,
      refObjectiveDescription,
      refWeightLoseQuantity,
      refWeightLoseDietTested,
      refWeightGainQuantity,
      refWeightGainDietTested,
      onCancelEdition,
      onSubmit,
    };
  },
});
