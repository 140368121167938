import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "p-grid" }
const _hoisted_5 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_6 = {
  key: 0,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_7 = {
  key: 1,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_8 = {
  key: 2,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_9 = {
  key: 3,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_10 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_11 = { for: "age" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MultiSelectBox = _resolveComponent("MultiSelectBox")!
  const _component_HealzInput = _resolveComponent("HealzInput")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.isAnthropometricDataNotReady)
    ? (_openBlock(), _createBlock(_component_EmptyState, {
        key: 0,
        heading: 'Titulo del empty state',
        icon: "icon-loading",
        text: 'Mensaje del empty state',
        textAtBottom: true
      }))
    : (_ctx.surveyCgmInitialFormDto)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_CardComponent, {
            cardTitle: _ctx.$t('personData.healzObjectivePatient'),
            "edit-mode": _ctx.editMode
          }, {
            headingActions: _withCtx(() => [
              _withDirectives((_openBlock(), _createBlock(_component_Button, {
                class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editMode = !_ctx.editMode))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: "icon-edit",
                    "aria-label": _ctx.$t('common.edit'),
                    "aria-hidden": "true"
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              }, 8, ["class"])), [
                [
                  _directive_tooltip,
                  _ctx.$t('common.edit'),
                  void 0,
                  { bottom: true }
                ]
              ])
            ]),
            cardContent: _withCtx(() => [
              (_ctx.surveyCgmInitialFormDto)
                ? (_openBlock(), _createElementBlock("form", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_MultiSelectBox, {
                          ref: "refObjectiveDescription",
                          modelValue: _ctx.surveyCgmInitialFormDto.healzObjectiveDescription,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.healzObjectiveDescription) = $event)),
                          editMode: _ctx.editMode,
                          options: _ctx.objectiveOptions,
                          title: _ctx.$t('personData.objectives.title')
                        }, null, 8, ["modelValue", "editMode", "options", "title"])
                      ]),
                      (_ctx.surveyCgmInitialFormDto.healzObjectiveDescription === _ctx.Objectives.WEIGHT_LOSS)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_MultiSelectBox, {
                              ref: "refWeightLoseQuantity",
                              modelValue: _ctx.surveyCgmInitialFormDto.weightLoseQuantity,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.weightLoseQuantity) = $event)),
                              editMode: _ctx.editMode,
                              options: _ctx.weightLoseQuantityOptions,
                              title: _ctx.$t('personData.weightLoseQuantity.title')
                            }, null, 8, ["modelValue", "editMode", "options", "title"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.surveyCgmInitialFormDto.healzObjectiveDescription === _ctx.Objectives.WEIGHT_LOSS)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_MultiSelectBox, {
                              ref: "refWeightLoseDietTested",
                              modelValue: _ctx.surveyCgmInitialFormDto.weightLoseDietTested,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.weightLoseDietTested) = $event)),
                              editMode: _ctx.editMode,
                              options: _ctx.weightLoseDietTestedQuantityOptions,
                              title: _ctx.$t('personData.weightLoseDietTested.title')
                            }, null, 8, ["modelValue", "editMode", "options", "title"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.surveyCgmInitialFormDto.healzObjectiveDescription === _ctx.Objectives.WEIGHT_GAIN)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_MultiSelectBox, {
                              ref: "refWeightGainQuantity",
                              modelValue: _ctx.surveyCgmInitialFormDto.weightGainQuantity,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.weightGainQuantity) = $event)),
                              editMode: _ctx.editMode,
                              options: _ctx.weightGainQuantityOptions,
                              title: _ctx.$t('personData.weightGainQuantity.title')
                            }, null, 8, ["modelValue", "editMode", "options", "title"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.surveyCgmInitialFormDto.healzObjectiveDescription === _ctx.Objectives.WEIGHT_GAIN)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createVNode(_component_MultiSelectBox, {
                              ref: "refWeightGainDietTested",
                              modelValue: _ctx.surveyCgmInitialFormDto.weightGainDietTested,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.weightGainDietTested) = $event)),
                              editMode: _ctx.editMode,
                              options: _ctx.weightGainDietTestedQuantityOptions,
                              title: _ctx.$t('personData.weightGainDietTested.title')
                            }, null, 8, ["modelValue", "editMode", "options", "title"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('personData.healzObjective.title')), 1),
                        _createVNode(_component_HealzInput, {
                          id: "age",
                          modelValue: _ctx.surveyCgmInitialFormDto.healzObjective,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.healzObjective) = $event)),
                          editable: _ctx.editMode,
                          type: "text"
                        }, null, 8, ["modelValue", "editable"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            footerActions: _withCtx(() => [
              _createVNode(_component_Button, {
                class: "p-button-secondary p-button-medium",
                label: _ctx.$t('common.cancel'),
                onClick: _ctx.onCancelEdition
              }, null, 8, ["label", "onClick"]),
              _createVNode(_component_Button, {
                class: "p-button p-button-info p-button-medium",
                loading: _ctx.waitSubmit,
                label: _ctx.$t('common.save'),
                onClick: _ctx.onSubmit
              }, null, 8, ["loading", "label", "onClick"])
            ]),
            _: 1
          }, 8, ["cardTitle", "edit-mode"])
        ]))
      : _createCommentVNode("", true)
}